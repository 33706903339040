import React from 'react';
import clsx from "clsx";
import styles from './modalBurger.module.scss';
import MainLogo from "../mainLogo/MainLogo";
import {Link} from "react-router-dom";

const Cross = (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 24.4707L24 2.47073" stroke="#FBCD20" strokeWidth="3.21004" strokeLinecap="round"/>
        <path d="M2 2L24 24" stroke="#FBCD20" strokeWidth="3.21004" strokeLinecap="round"/>
    </svg>
);

const LINKS =[
    {title: 'Главная', comment: '', href: '/', hash: ''},
    {title: 'Услуги', comment: '', href: '/', hash: '#services'},
    {title: 'Категория A', comment: '', href: '/categories/category_a', hash: ''},
    {title: 'Категория C', comment: '', href: '/categories/category_c', hash: ''},
    {title: 'Категория CE', comment: '', href: '/categories/category_ce', hash: ''},
    {title: 'Категория D', comment: '(Переподготовка с категории B)', href: '/categories/category_d_after_b', hash: ''},
    {title: 'Категория D', comment: '(Переподготовка с категории C)', href: '/categories/category_d_after_c', hash: ''},
    {title: 'Водитель-международник', comment: '', href: '/categories/international_driver', hash: ''},
    {title: 'Стажировка на фуре', comment: '', href: '/categories/additional_lessons', hash: ''},
    {title: 'Индивидуальные занятия', comment: '', href: '/categories/individual_lessons', hash: ''},
    {title: 'Повышение квалификации водителей такси', comment: '', href: '/categories/kursy_voditeley_taksi', hash: ''},
    {title: 'О нас', comment: '', href: '/', hash: '#about'},
    {title: 'Акции', comment: '', href: '/promotions', hash: ''},
    {title: 'Новости', comment: '', href: '/news/allnews', hash: ''},
    {title: 'Контакты', comment: '', href: '/contacts', hash: ''},
];

const ModalBurger = ({active, setActive}) => {

    const closeModal = () => {
        document.body.style.overflow = "";
        setActive(false)
    }

    return (
        <div className={clsx(styles.modalBurger, active && styles.active)} onClick={closeModal}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <div onClick={closeModal} className={styles.modalHeader}><MainLogo/>{Cross}</div>
                <div className={styles.modalNavigation}>
                    {LINKS.map((link, index) =>
                        <Link onClick={closeModal} to={{pathname: link.href, hash: link.hash}} key={index}>
                            {link.title}
                            <br/>
                            {link.comment && <div className={styles.navigationComment}>{link.comment}</div>}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalBurger;